import { Injectable } from '@angular/core';

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private _isVisible = false;

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
  ) {}

  dismiss() {
    this.snackBar.dismiss();
  }

  public isVisible(): boolean {
    return this._isVisible;
  }

  showToaster(translateKey: string, options?: any, translateParams?: any) {
    let duration = 20000;
    let colorClass = 'info-snack-bar';

    if (options) {
      switch (options.type) {
        case 'success':
          colorClass = 'success-snack-bar';
          break;
        case 'error':
          console.error(`Toaster error '${translateKey}' params: ${translateParams}`);
          colorClass = 'error-snack-bar';
          break;
        default:
          colorClass = 'info-snack-bar';
          break;
      }
    }

    const config = new MatSnackBarConfig();
    // The style for the snackbar is set in styles.scss based on this class.
    config.panelClass = [colorClass];
    config.duration = duration;

    const action = options.action || 'HIDE';

    this._isVisible = true;
    this.snackBar
      .open(this.translate.instant(translateKey), action, config)
      .afterDismissed()
      .subscribe((result) => {
        this._isVisible = false;
      });
  }
}
