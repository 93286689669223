// @ts-strict-ignore
import countries from '@assets/data/countries.json';
import * as moment from 'moment';
import { Currency } from '@app/core/types/common';

export const AIRLINE_DEFAULT_COMMISSION_PERCENTAGE = 4;
export const AIRLINE_DEFAULT_MINIMUM_COMMISSION = 800;

export class UtilsHelper {
  constructor() {}

  static kmToMiles(kms: number): number {
    return Math.round(kms * 0.6214);
  }

  static DATE_FORMAT = 'DD/MM/YYYY';
  static TIME_FORMAT = 'HH:mm';
  static DATE_TIME_FORMAT = `${UtilsHelper.DATE_FORMAT} ${UtilsHelper.TIME_FORMAT}`;

  static formatDateUtc(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).utc().format(UtilsHelper.DATE_FORMAT);
  }

  static formatDate(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).format(UtilsHelper.DATE_FORMAT);
  }

  static formatDateTimeUtc(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).utc().format(UtilsHelper.DATE_TIME_FORMAT);
  }

  static formatDateTime(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).format(UtilsHelper.DATE_TIME_FORMAT);
  }

  static formatTimeUtc(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).utc().format(UtilsHelper.TIME_FORMAT);
  }

  static formatTime(date: moment.Moment | Date | string): string | undefined {
    if (!date || date === '') {
      return undefined;
    }
    return moment(date).format(UtilsHelper.TIME_FORMAT);
  }

  static formatDueDate(airlineDueDate: Date): string | undefined {
    if (!airlineDueDate) {
      return undefined;
    }
    const dueDate = moment(airlineDueDate).utc();
    /***
     TRAVEL AGENCY:

     Show the due date 2 weeks earlier to make sure myPlane can manage
     the money transfer to airline by airline due date.
     **/
    let dueDateStr = dueDate.format(UtilsHelper.DATE_FORMAT);
    if (dueDate.utc().isBefore(moment().utc())) {
      dueDateStr = 'Immediately';
    }
    return dueDateStr;
  }

  static removeSpecialChars(string): any {
    let _string = string;
    if (_string && _string.length > 0) {
      _string = _string.replace('/', '');
      _string = _string.replace('(', '');
      _string = _string.replace(')', '');
    }
    return _string;
  }

  static getCountries(): any[] {
    return countries;
  }

  static getCountryByCode(code): string {
    const countryList = this.getCountries();
    if (this.validateCountryCodeAlpha2(code)) {
      return countryList.find((country) => country.alpha2Code === code).englishShortName;
    }
    return '';
  }

  /*** Returns 3 character Country Codes Ie. FIN ***/
  static getCountryCodesAlpha3(): any[] {
    return this.getCountries().map((country) => {
      return country.alpha3Code;
    });
  }

  /*** Returns 2 character Country Codes Ie. FI ***/
  static getCountryCodesAlpha2(): any[] {
    return this.getCountries().map((country) => {
      return country.alpha2Code;
    });
  }

  static validateCountryCodeAlpha3(code: string): boolean {
    return this.getCountryCodesAlpha3().indexOf(code) > -1;
  }

  static validateCountryCodeAlpha2(code: string): boolean {
    return this.getCountryCodesAlpha2().indexOf(code) > -1;
  }

  clearExoticChars(string): string {
    let _string = string;
    if (_string && _string.length > 0) {
      _string = _string.replace(/[äÄåÅâÂãÃáÁàÀ]/g, 'a');
      _string = _string.replace(/[ëËêÊéÉèÈ]/g, 'e');
      _string = _string.replace(/[ïÏîÎíÏìÌ]/g, 'i');
      _string = _string.replace(/[öÖôÔõÕóÓòÒ]/g, 'o');
      _string = _string.replace(/[üÜûÛúÚùÙ]/g, 'u');
    }
    return _string;
  }

  static formatDuration = (hours) => {
    return '' + Math.floor(hours) + ' h ' + Math.floor((hours % 1) * 60) + ' min';
  };

  /**
   * Formats the number as currency
   * @param num number
   * @param currency currency code (i.e. EUR or USD)
   * @param decimals number of decimals to use
   */
  static formatCurrency = (
    value: number | null | undefined,
    currency: Currency | undefined = undefined,
    decimals: number = 2,
  ) => {
    if (value === undefined || value === null || isNaN(value)) return '';
    const formattedValue = value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

    if (!currency) return formattedValue;
    return currency + ' ' + formattedValue;
  };

  static todayDateUTC = () => {
    return moment().startOf('day').utcOffset(0, true);
  };

  static splitIntoChunks(array, chunkSize) {
    return array.flatMap((x, i) => (i % chunkSize === 0 ? [array.slice(i, i + chunkSize)] : []));
  }

  static isExternalPage(): boolean {
    return location.href.indexOf('external') >= 0;
  }
}
